import { useLazyQuery } from "@apollo/client";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { DateTime } from "luxon";
import React, { ReactNode, createContext, useContext, useReducer } from "react";
import invariant from "tiny-invariant";
import { PreferencesWeights } from "../chat/ai-chat/Sidebar/optimize-week/constants";
import { eventToast } from "../web-app-calendar/notifiation-event/EventToast";
import {
  OnDemandDefragDocument,
  OnDemandDefragQuery,
} from "./apollo/__generated__/OnDemandDefrag.generated";

type SchedulingProposalDefrag = NonNullable<
  OnDemandDefragQuery["viewer"]["user"]
>["onDemandDefrag"];

const INIT_ODD_PROPOSAL: OddProposal = {
  visible: false,
  proposal: null,
  loading: false,
};

export type Person = {
  email: string;
  displayName: string;
  givenName: string | null;
  familyName: string | null;
  externalImageUrl: string | null;
  isMe: boolean;
  id: string;
};

export type Attendee = {
  id: string;
  isOptional: boolean;
  person: Person;
  user: {
    id: string;
  } | null;
};

export type OddProposal = {
  visible: boolean;
  proposal: SchedulingProposalDefrag | null;
  loading: boolean;
};

type DefragProposalReadObj = {
  onDemandDefrag: OddProposal;
};

type DefragProposalWriteObj = {
  handleOnDemandDefragRequest: (proposal: SchedulingProposalDefrag | null) => void;
  cancelOnDemandDefragRequest: () => void;
  removeDiffInOnDemandDefrag: (diffId: string) => void;
  setOnDemandDefragLoading: (loading: boolean) => void;
  fetchPlanMyWeek: (weights: PreferencesWeights, startTime: DateTime, endTime: DateTime) => void;
};

type InitOddProposalAction = {
  type: "InitOddProposal";
  proposal: OddProposal;
};

type ClearOddProposalAction = {
  type: "ClearOddProposal";
};

type RemoveDiffProposalAction = {
  type: "RemoveDiffOddProposal";
  diffId: string;
};

type LoadingOddProposalAction = {
  type: "LoadingOddProposal";
  loading: boolean;
};

type OddProposalAction =
  | InitOddProposalAction
  | ClearOddProposalAction
  | RemoveDiffProposalAction
  | LoadingOddProposalAction;

function oddProposalReducer(state: OddProposal, action: OddProposalAction) {
  switch (action.type) {
    case "LoadingOddProposal":
      return { ...state, loading: action.loading };
    case "InitOddProposal":
      return { ...state, ...action.proposal };
    case "ClearOddProposal":
      return { ...INIT_ODD_PROPOSAL };
    case "RemoveDiffOddProposal":
      if (state.proposal) {
        const newDiffs = state.proposal?.diffBlocks?.[0].diffs?.filter(
          (diff) => diff.id !== action.diffId,
        );
        return {
          ...state,
          proposal: ({
            ...state.proposal,
            diffBlocks: [{ ...state.proposal?.diffBlocks, diffs: newDiffs }],
          } as unknown) as SchedulingProposalDefrag,
        };
      }
      return { ...state };
  }
}

const ReadContext = createContext<DefragProposalReadObj>({
  onDemandDefrag: INIT_ODD_PROPOSAL,
});
const WriteContext = createContext<DefragProposalWriteObj | null>(null);

export const DefragProposalProvider = ({ children }: { children: ReactNode }) => {
  const track = useTracking();
  const [onDemandDefrag, dispatchODD] = useReducer(oddProposalReducer, INIT_ODD_PROPOSAL);
  const [getOnDemandDefrag] = useLazyQuery(OnDemandDefragDocument);
  // const calendarDispatch = useUpdateCalendar();
  const fetchPlanMyWeek = (weights: PreferencesWeights, startTime: DateTime, endTime: DateTime) => {
    setODDLoading(true);
    void getOnDemandDefrag({
      variables: {
        startTime,
        endTime,
        weightOverrides: weights,
      },
      onCompleted: (data) => {
        const defragProposal = data?.viewer?.user?.onDemandDefrag || null;
        track(TrackingEvents.CHAT.ODD.ODD_SUGGESTIONS_RECEIVED, { proposalId: defragProposal?.id });

        if (defragProposal?.diffBlocks?.[0]?.diffs?.length) {
          handleOddRequest(defragProposal);
          // Move calendar to the earliest diff start
          // const diffs = defragProposal?.diffBlocks?.[0]?.diffs;
          // const anchorDate = getEarliestDiffStart(diffs);
          // calendarDispatch({
          //   type: "jumpTo-weekOf",
          //   payload: anchorDate.toISODate(),
          // });
        } else {
          eventToast.error({
            operation: "DEFRAG",
            title: "Nothing available to move",
          });
        }
        setODDLoading(false);
      },
    });
  };

  const handleOddRequest = (proposal: SchedulingProposalDefrag | null) => {
    if (proposal) {
      dispatchODD({
        type: "InitOddProposal",
        proposal: {
          visible: true,
          proposal,
          loading: false,
        },
      });
    }
  };

  const setODDLoading = (loading: boolean) => {
    dispatchODD({
      type: "LoadingOddProposal",
      loading,
    });
  };

  const removeOddDiff = (diffId: string) => {
    dispatchODD({ type: "RemoveDiffOddProposal", diffId });
  };

  const clearOddRequest = () => {
    dispatchODD({ type: "ClearOddProposal" });
  };

  return (
    <WriteContext.Provider
      value={{
        handleOnDemandDefragRequest: handleOddRequest,
        cancelOnDemandDefragRequest: clearOddRequest,
        removeDiffInOnDemandDefrag: removeOddDiff,
        setOnDemandDefragLoading: setODDLoading,
        fetchPlanMyWeek,
      }}
    >
      <ReadContext.Provider value={{ onDemandDefrag: onDemandDefrag }}>
        {children}
      </ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useDefragProposal = () => useContext(ReadContext);
export const useUpdateDefragProposal = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateDefragProposal must be within DefragProposalProvider");
  return context;
};
