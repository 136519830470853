import { Skeleton } from "@clockwise/design-system";
import * as React from "react";

export interface SchedulingLinkLoadingProps {}

export const SchedulingLinkLoading = ({}: SchedulingLinkLoadingProps) => {
  return (
    <div
      className="cw-flex cw-justify-start cw-gap-[100px] cw-max-w-[800px] cw-min-w-[700px] cw-m-[50px_70px]"
      role="progressbar"
    >
      <div>
        <div className="cw-flex cw-items-center cw-gap-3 cw-mb-10">
          <Skeleton width={80} height={80} className="cw-rounded-full" />
          <div className="cw-flex cw-flex-col cw-gap-3">
            <Skeleton width={200} height={24} />
            <Skeleton width={200} height={24} />
          </div>
        </div>
        <Skeleton className="cw-rounded-[30px] cw-mb-[55px]" width={135} height={35} />
        <Skeleton className="cw-rounded-[10px]" width={300} height={350} />
      </div>
      <div className="cw-hidden sm:cw-block md:cw-flex cw-w-[400px] cw-gap-[33px] cw-mt-[43px]">
        <div>
          <Skeleton className="cw-mb-[10px]" width={160} height={70} />
          <div className="cw-flex cw-flex-col cw-gap-[15px]">
            <Skeleton className="cw-rounded-[10px]" width={225} height={85} />
            <Skeleton className="cw-rounded-[10px]" width={225} height={85} />
            <Skeleton className="cw-rounded-[10px]" width={225} height={85} />
          </div>
        </div>
        <div>
          <Skeleton className="cw-mb-[10px]" width={160} height={70} />
          <div className="cw-flex cw-flex-col cw-gap-[15px]">
            <Skeleton className="cw-rounded-[10px]" width={225} height={85} />
            <Skeleton className="cw-rounded-[10px]" width={225} height={85} />
          </div>
        </div>
      </div>
    </div>
  );
};
